import { Controller } from "stimulus"
import "magnific-popup";
import qrcode from "packs/jquery-qrcode-0.14.0.min";
import {MDCTextField} from "@material/textfield";

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    var _this = this;
    $(".group .qr").each(function( index ) {
      var el = $(".group .qr")[index];
      if (el.innerHTML.length < 1)
        _this.qrGen("https://v.triplet.io?c=" + el.dataset.code, el.dataset.code, $(el))
    });

    var big_qr = document.querySelector(".group #qr");
    
    if (big_qr != null && big_qr.innerHTML.length < 1) {
      _this.qrGenBig("https://v.triplet.io?c=" + big_qr.dataset.code, big_qr.dataset.code, $(big_qr))
    }
  }

  showNewGroupForm() {
    $('.new_group_form').show(200, function() {
      const field = MDCTextField.attachTo(document.querySelector('.new_group_form .mdc-text-field'));
      field.focus();
    });

  }
  

  getGroups(event) {
    let assignment_id = 1;

    if (event) {
      assignment_id = event.currentTarget.dataset.aid;
    }

    var url = "/tehtavat/";
 
    $.get( url, {
      assignment_id: assignment_id
    })
    .done(function( data ) {
      if (data.length > 1) {
        $("#groups").html(data);
      }
    });
  }

  getGroup(event) {
    let group_id = 1;

    if (event) {
      group_id = event.currentTarget.dataset.id;
    }

    var url = "/tehtavat/" + group_id;
 
    $.get( url, {})
    .done(function( data ) {
      if (data.length > 1) {

        $.magnificPopup.open({
          items: {
            src: data, 
            type: 'inline'
          }
        });
      }
    });
  }

  qrGen(url, label, el) {
    el.show();
    el.qrcode({
      render: 'canvas',
      minVersion: 1,
      maxVersion: 40,
      ecLevel: 'L',
      left: 0,
      top: 0,
      size: 50,
      fill: '#064555',
      background: null,
      text: url,
      radius: 0,
      quiet: 0,
      mode: 0,
      mSize: 0.17,
      mPosX: 0.5,
      mPosY: 0.5,
      image: null
    });
  }

  qrGenBig(url, label, el) {
    el.show();
    el.qrcode({
      render: 'canvas',
      minVersion: 1,
      maxVersion: 40,
      ecLevel: 'H',
      left: 0,
      top: 0,
      size: 150,
      fill: '#064555',
      background: null,
      text: url,
      radius: 0,
      quiet: 0,
      mode: 0,
      mSize: 0.17,
      mPosX: 0.5,
      mPosY: 0.5,
      image: null
    });
  }

}
