import { Controller } from "stimulus"
import $ from 'jquery';
import Rails from '@rails/ujs';
import {MDCTextField} from '@material/textfield';

let timer;

export default class extends Controller {
  static targets = [] 

  initialize() {
    let _this = this;

    const mainSearchInput = document.querySelector("#header #search_key");
    const mobileSearchInput = document.querySelector("#small-header #search_key");

    if($('#small-header').css('display') == 'none')
      mainSearchInput.addEventListener("keyup", function (e) { 
        clearTimeout(timer);
        timer = setTimeout(() => {
          startMainSearch();
        }, 300);
      });
    else 
      $("#small-header #search_key").on("keyup", function (event, data) {
        if (event.which == 13) {
          startMobileSearch();
        }
      });

    function startMainSearch() {
      var key = $("#header #search_key").val().toLowerCase();
      if (key.length > 1) {
        _this.getAutocompleteList(key);
      } else {
        $("#search-pointer").hide();
        $("#search-content").hide();
        $("#main-content").show();
        $('#tags-list').html("");
        $('#autocomplete_list').html("");
      }
    }

    function startMobileSearch() {
      var key = $("#small-header #search_key").val().toLowerCase();
      if (key.length > 1) {
        $(".nav_btn")[0].click();
        _this.getAutocompleteList(key);
      } else {
        $("#search-pointer").hide();
        $("#search-content").hide();
        $("#main-content").show();
        $('#tags-list').html("");
        $('#autocomplete_list').html("");
      }
    }
  }

  getAutocompleteList(key) {
    var autocomplete_list = "",
        tags_list = "";

    var key = $.trim(key);

    if (key.length > 1) {
      $("#main-content").hide();
      $("#search-content").show();
      $("#search-pointer").show();
      console.log(".... Sending ajax");
      $.ajax({
        data: {search: {key: key}},
        dataType:'json',
        type:'get',
        url: "/search",
        success : function(data, textStatus, jqXHR) {
                    autocomplete_list = "";
                    tags_list = "";
                    if (data["tags"] != null) tags_list = data["tags"];
                    if (data["subjects"] != null) autocomplete_list = data["subjects"];
                    if (data["articles"] != null) autocomplete_list = autocomplete_list + data["articles"];
                    if (data["collections"] != null) autocomplete_list = autocomplete_list + data["collections"];
                    if (autocomplete_list.length < 1) autocomplete_list = "Mitään ei löytynyt."
                    $('#tags-list').html(tags_list);
                    $('#autocomplete_list ').html(autocomplete_list);
                    $(window).scrollTop(0);
                  }
      });
    } else {
      $('#tags-list').html("");
      $('#autocomplete_list').html("");
      $("#search-pointer").hide();
      $("#search-content").hide();
      $("#main-content").show();
    }
  }

  hideSearch() {
    $("#search-pointer").hide();
    $("#search-content").hide();
    $("#main-content").show();
    $("#header #search_key").val("");
    $("#small-header #search_key").val("");
  }

}