import { Controller } from "stimulus"
import $ from 'jquery';
import Rails from '@rails/ujs';
import {MDCDrawer} from "@material/drawer";

export default class extends Controller {
  static targets = [] 
  drawer = null;

  initialize() {
    const width = $(window).width();
    if (width > 0 && width <= 800) {
      $(".mdc-drawer.mdc-drawer--open").removeClass("mdc-drawer--open").css("opacity", "1");

      $('<div class="mdc-drawer-scrim"></div>').insertAfter('.mdc-drawer');

    }

    if ($("#my-group-switch").hasClass('active') == false){
      $(".mdc-drawer #my-group").hide();
    } 

    this.drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));
  }

  toggleDrawer() {
  	this.drawer.open = !this.drawer.open;
  }

  toggleMyGroup() {
    if ($('#my-group-switch').hasClass('active') == true) {
      $(".mdc-drawer #my-group").hide();
      $('#my-group-switch').removeClass('active');
    } else {
      $(".mdc-drawer #my-group").show();
      $('#my-group-switch').addClass('active');
    }
  }

  acceptCookies() {
    document.cookie = "gotit=true"
    $("#cookies_popup").hide()

    location.reload();
  }

  rejectCookies() {
    document.cookie = "gotit=false"
    $("#cookies_popup").hide()
  }

 }