import "magnific-popup";
import $ from 'jquery'

export function initMagnificPopup() {
  
  $('.popup_link').magnificPopup({
    type: 'ajax',
    focus: '.white-popup input',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);

        setTimeout(function() {window.mdcAutoInit();}, 400);
      }
    }
  });

  $('.popup_link.close_confirm').magnificPopup({
    type: 'ajax',
    focus: '.white-popup input',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);
        $.magnificPopup.instance.close = function() {
          if ($.magnificPopup.instance.content != null && $.magnificPopup.instance.content != undefined && $.magnificPopup.instance.content.length > 0 && $.magnificPopup.instance.content.hasClass("close_with_confirmation")) {
            var conf_text = $.magnificPopup.instance.content.find(".magnific-close-confirm").html();
            if (conf_text !== undefined) {
              var confirmed = confirm(conf_text);
            }
            
            if(!confirmed) {
              return;
            } else
              location.reload();
            
          } 
          $.magnificPopup.proto.close.call(this);
        };
        //setTimeout(function() {window.mdcAutoInit();}, 400);
      }
    }
  });

  $('.inline_popup').magnificPopup({
    type: 'inline',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);
        //setTimeout(function() {window.mdcAutoInit();}, 400);
      }
    }
  });


  $('.inline_popup.close_confirm').magnificPopup({
    type: 'inline',
    alignTop: true,
    callbacks: {
      open: function() {
        setTimeout(function() {initMagnificPopup();}, 400);
        $.magnificPopup.instance.close = function() {

          if ($.magnificPopup.instance.content.hasClass("close_with_confirmation")) {
            var conf_text = $.magnificPopup.instance.content.find(".magnific-close-confirm").html();
            if (conf_text !== undefined) {
              var confirmed = confirm(conf_text);
            }
            
            if(!confirmed) {
              return;
            } else
              location.reload();
            
          } 

          $.magnificPopup.proto.close.call(this);
        };

      }
    }
  });

}