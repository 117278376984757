import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  initialize () {
    // Is run first. In this case we don't need to worry about anything.
  }

  connect() {
    let realtimeGroupsController = this;

    this.subscription = consumer.subscriptions.create(
      {
        channel: "GroupsChannel",
        group_id: this.data.get("group")
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },
        disconnected() {
          // Called when the subscription has been terminated by the server
        },
        received(data) {
          var items = [];
          var students = {};

          data = data["results"]["results"];

          if (data.length > 0) {

            $.each( data, function( index ) {
              var key = data[index]['student_uid'] + data[index]['result_token'];
              if (students[key] === undefined) {
                students[key] = {"name": data[index]['name'], "answers_size": 0, "answers": [], "right_answers": 0, "questions_amount": data[index]['questions_amount']};
              }
              students[key]['answers_size'] = students[key]['answers_size'] + 1;
              students[key]['answers'].push(data[index]['result']);
              if (data[index]['result'] == true) {
                students[key]['right_answers'] = students[key]['right_answers'] + 1;
              }
            });

            $.each( students, function( key, value ) { 
              var line = "";
              var line_index = 0;

              $.each( students[key]['answers'], function( index, v) {
                line = line + "<span class='material-icons " + v + "'>star</span>";
                line_index += 1;
              });


              if (value['questions_amount'] > line_index) {

                for (var i = 0; i < value['questions_amount']  - line_index; i++) { 
                  line = line + "<span class='material-icons'>star_border</span>";
                }

              }
              
              items.push( "<div class='student'><div class='name'><span>" + value['name'] + "</span>" + "</div><div class='bar'>" + line + "</div></div>" );
            });

            $(".white-popup .results .list").html(items);

          } else {
            $(".white-popup .results .list").html("Ei tuloksia");
          }

          
        }
      }
    );

    //setInterval(function(){ 
      realtimeGroupsController.subscription.send({type: "results", group: realtimeGroupsController.data.get("group")})
    //}, 7000);
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

}