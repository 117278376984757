require("packs/material")
require("jquery")
require("packs/jquery-qrcode-0.14.0.min")
require("packs/magnific")
require("channels")

import $ from 'jquery'

import {initMagnificPopup} from '../packs/initMagnificPopup';

import Rails from '@rails/ujs';
Rails.start();

import "controllers"
import "../styles/media"

window.addEventListener('DOMContentLoaded', (event) => {
  //NOTE: => javascript_pack_tag 'admin'  schould be WITHOUT  'async: true' to be able to fire DOMContentLoaded from JS

  initMagnificPopup();
    
  $(".tabs .tab").on("click", function() {
    if ( $(this).hasClass('active') == false) {
      $(".tabs .tab.active").removeClass("active");
      $(this).addClass('active');
      $(".tab_view.active").removeClass("active");
      $(".tab_view." + $(this).attr("id")).addClass("active");
    }
  });

  if ($("#pagination_button").length > 0) {

    var scroll = function () {
      if (($("#pagination_button.infinite-scroll") !== undefined) && ($("#pagination_button.infinite-scroll").hasClass("hidden") == false) && ($("#pagination_button.infinite-scroll").hasClass("loading") == false)) {
        var pos = $(window).scrollTop();  
        var pagination_pos = 0;
        var window_height = window.innerHeight;
        if (document.getElementById("pagination_button") != null) {
          pagination_pos = document.getElementById("pagination_button").offsetTop;
          if (pos > pagination_pos - window_height - 200) {
            $("#pagination_button").click();
          }   
        }
      }
    };

    var raf = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame;
    var $window = $(window);
    var lastScrollTop = $window.scrollTop();

    if (raf) {
      loop();
    }

    function loop() {
      var scrollTop = $window.scrollTop();
      if (lastScrollTop === scrollTop) {
        raf(loop);
        return;
      } else {
        lastScrollTop = scrollTop;

        scroll();
        raf(loop);
      }
    }

  }

});
