import { Controller } from "stimulus"
import $ from 'jquery';
import Rails from '@rails/ujs';
import {MDCTabBar} from "@material/tab-bar";

export default class extends Controller {
  static targets = [ "grid" ]
  tabBar = null;

  initialize() {
    this.tabBar = new MDCTabBar(document.querySelector('.mdc-tab-bar'));
  }


  toggleBookmark(event) {
    var article_id = event.currentTarget.dataset.articleid;
    var status = event.currentTarget.dataset.status;
    var url = "/articles/" + article_id + "/favorite";
    var method = "POST";

    if (status == "true") {
      method = "DELETE"
    }

    $.ajax({
      url: url,
      type: method,
      data: {},
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      },
      success: function (data) {
        if (data.success == true) {
          if (status == "true") {
            $(".mdc-button.marked").addClass('hidden');
            $(".mdc-button.unmarked").removeClass('hidden');
          } else {
            $(".mdc-button.unmarked").addClass('hidden');
            $(".mdc-button.marked").removeClass('hidden');
          }
          
        }
      }
    });

  }

  toggleCollectionForm() {
    if ($(".tools .collection_edit").hasClass("hidden") == true) {
      $(".tools .collection_edit").removeClass("hidden");
    } else {
      $(".tools .collection_edit").addClass("hidden");
    }
  }

  toggleSubsList() {
    if ($(".tools .subs_list").hasClass("hidden") == true) {
      $(".tools .subs_list").removeClass("hidden");
    } else {
      $(".tools .subs_list").addClass("hidden");
    }
  }

  
  getArticles(event) {
    let filter = null;
    let value = null;
    let order = null;
    let page = 1;

    if (event) {
      filter = event.currentTarget.dataset.f;
      value = event.currentTarget.dataset.v;
      order = event.currentTarget.dataset.o;
      page = event.currentTarget.dataset.p;
    }

    var url = "/uutiset";

    if (page < 2 || $("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( url, {
        f: filter,
        v: value,
        o: order,
        p: page,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {
          $("#card-grid").append(data);
          $("#pagination_button").attr("data-p", parseInt(page) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

  getCollectionArticles(event) {
    let id = null;
    let filter = null;
    let value = null;
    let order = null;
    let page = 1;

    if (event) {
      id = event.currentTarget.dataset.id;
      filter = event.currentTarget.dataset.f;
      value = event.currentTarget.dataset.v;
      order = event.currentTarget.dataset.o;
      page = event.currentTarget.dataset.p;
    }

    var url = "/kokoelmat/" + id;

    if (page < 2 || $("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( url, {
        f: filter,
        v: value,
        o: order,
        p: page,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {
          $(".card-grid").append(data);
          $("#pagination_button").attr("data-p", parseInt(page) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

  getTagArticles(event) {
    let id = null;
    let filter = null;
    let value = null;
    let page = 1;

    if (event) {
      id = event.currentTarget.dataset.id;
      filter = event.currentTarget.dataset.f;
      value = event.currentTarget.dataset.v;
      page = event.currentTarget.dataset.p;
    }

    var url = "/oppiaineet/" + id;

    if (page < 2 || $("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( url, {
        f: filter,
        v: value,
        p: page,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {
          $(".card-grid").append(data);
          $("#pagination_button").attr("data-p", parseInt(page) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

  setArticleToCollection(event) {
    let collection_id = null;
    let article_id = null;
    let isSelected = "false";

    if (event) {
      collection_id = event.currentTarget.dataset.collectionid;
      article_id = event.currentTarget.dataset.articleid;
      isSelected = event.currentTarget.dataset.selected;
    }
      
    let _target = event.currentTarget;

    if (isSelected == "true") {
      var url = "/article_collections/" +  collection_id;
      let body = {article_id: article_id};

      fetch(url, {
        method: 'DELETE',
        mode: 'same-origin', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(body),
      })
      .then((response) => {
        _target.dataset.selected = "false";
      })
      .then((data) => {})
      .catch((error) => {});
    } else {
      var url = "/article_collections" ;
      let body = {article_id: article_id, collection_id: collection_id};

      fetch(url, {
        method: 'POST',
        mode: 'same-origin', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify(body),
      })
      .then((response) => {
        _target.dataset.selected = "true";
        $("#add-collection.mdc-button--raised").removeClass('mdc-button--raised');
      })
      .then((data) => {})
      .catch((error) => {});
    }

  }

  scrollTabsRight(event) {
    const container = document.querySelector('.mdc-tab-scroller__scroll-area');
    let offset = 0;
    let currentIndex = 0;
    let maxIndex = $(".mdc-tab-bar .mdc-tab").length - 1;

    $(".mdc-tab-bar .mdc-tab").each(function( index ) {
      offset = parseInt(container.offsetWidth) - parseInt($(this).width()) - parseInt($(this).offset().left) + parseInt($(".mdc-tab-scroller__scroll-area").offset().left);
      if (offset > 0) currentIndex = index;
    });

    $(".mdc-tab-bar .gradient.left, .mdc-tab-bar .left_arrow").show();
    
    if (currentIndex < maxIndex) {
      this.tabBar.scrollIntoView(currentIndex + 1);
    }

  }

  scrollTabsLeft(event) {
    const container = document.querySelector('.mdc-tab-scroller__scroll-area');
    let offset = 0;
    let currentIndex = 0;

    $(".mdc-tab-bar .mdc-tab").each(function( index ) {
      offset = parseInt($(".mdc-tab-scroller__scroll-area").offset().left) - parseInt($(this).offset().left);
      if (offset > 0) currentIndex = index;
    });

    if (currentIndex >= 0) {
      this.tabBar.scrollIntoView(currentIndex);
    }
  }

}
