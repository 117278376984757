import { Controller } from "stimulus"
import $ from 'jquery';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "grid" ]

  getLetters(event) {
    let page = 1;

    if (event) {
      page = event.currentTarget.dataset.p;
    }

    var url = "/uutiskirjeet";

    if (page < 2 || $("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( url, {
        p: page,
        pagination: true,
      })
      .done(function( data ) {
        if (data.length > 1) {
          $(".card-grid").append(data);
          $("#pagination_button").attr("data-p", parseInt(page) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

}
