import { Controller } from "stimulus"
import $ from 'jquery';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "player" ]

  initPlayer(event) {
    var _this = this;
    this.prepareVideo(this.data.get("url"), _this);
  }

  prepareVideo(videoUrl, _this) {
    if (videoUrl != null && videoUrl != 'null') {
      // video_height = $(".white .cover img").height();
      
      // if (video_height < 10) video_height = 'auto';

      this.addHTML5Video(videoUrl, _this);

    }
  }

  // Adds HTML5 video tag
  addHTML5Video(_videoUrl,_this) {
    var video = _this.playerTarget;
    video.muted = 'muted';
    video.controls = true;
    //video.poster = "";

    video.onclick= function () {this.paused ? this.play() : this.pause();};

    if(Hls.isSupported()) {
      var hls = new Hls();
      console.log(_videoUrl);
      hls.loadSource(_videoUrl);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED,function() {
        //video.play();
      });
   }
   // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
   // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
   // This is using the built-in support of the plain video element, without using hls.js.
   // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
   // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = _videoUrl;
      video.muted = 'muted';
      video.addEventListener('loadedmetadata',function() {
        //video.play();
      });
    }

    $(".white .cover .play, .white .cover img").hide();
  }

}
